import React from "react";
import { Link } from "react-router-dom";

const Navbar_Hiltraud = () => {

    return(
        <nav>
            <p class="custom-links">
                <Link to="/">home</Link>
                <Link to="/hiltraud">persönliches</Link>
                <Link to="/hiltraud/gallerie">gallerie</Link>
            </p>
        </nav>
    );
}

export default Navbar_Hiltraud;