import React from "react";

const Header_Andrea = () => {

    return (
        <header>
            <center>
                <img src="../images/header/andrea/1.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/2.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/3.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/4.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/5.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/6.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/7.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/10.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/andrea/9.webp" height="100" draggable="false" />&nbsp;
            </center>
        </header>
    );

}

export default Header_Andrea;