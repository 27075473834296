import React from "react";

const Header_General = () => {

    return (
        <header>
            <center>
                <img src="images/header/both/1.webp" height="100" draggable="false" />&nbsp;
                <img src="images/header/both/2.webp" height="100" draggable="false" />&nbsp;
                <img src="images/header/both/3.webp" height="100" draggable="false" />&nbsp;
                <img src="images/header/both/9.webp" height="100" draggable="false" />&nbsp;
                <img src="images/header/both/8.webp" height="100" draggable="false" />&nbsp;
                <img src="images/header/both/10.webp" height="100" draggable="false" />&nbsp;
            </center>
        </header>
    );

}

export default Header_General;