import React, { useEffect, useState } from "react";
import Header_Hiltraud from "../components/header/Header_Hiltraud";
import Navbar_Hiltraud from "../components/navbar/Navbar_Hiltraud";
import ApiController from "../controller/ApiController";

const Hiltraud = () => {

    const [data, setData] = useState([]);

    const [leftData, setLeftData] = useState([]);
    const [rightData, setRightData] = useState([]);

    useEffect(() => {
        if (data.length === 0) {
            ApiController.getPersonalHiltraud().then((response) => { setData(response); });
        }
    }, []);

    useEffect(() => {

        if (data.length !== 0) {
            data.forEach(object => {
                if (object.side === "LEFT") {
                    let leftSide = object.description.split(/(?:\r\n|\r|\n)/g);
                    setLeftData(leftSide);
                } else if (object.side === "RIGHT") {
                    let rightSide = object.description.split(/(?:\r\n|\r|\n)/g);
                    setRightData(rightSide);
                }
            });
        }

    }, [data]);

    return (
        <div>
            <Header_Hiltraud />
            <Navbar_Hiltraud />
            <div class="wrapper">
                <div className="personlich1">
                    <h3>
                        {leftData.map(content => (
                            <p align="justify" style={{ fontFamily: 'Oswald', fontWeight: 300 }} >
                                <img src="images/punkt.jpg" width="15" style={{ paddingRight: "10px" }} /> {content} <br />
                            </p>
                        ))}

                    </h3>
                </div>
                <div className="personlich2">
                    <h3>
                        {rightData.map(content => (
                            <p align="justify" style={{ fontFamily: 'Oswald', fontWeight: 300 }} >
                                <img src="images/punkt.jpg" width="15" style={{ paddingRight: "10px" }} /> {content} <br />
                            </p>
                        ))}
                    </h3>
                </div>
            </div>
        </div>
    );

}

export default Hiltraud;