import React, { useEffect, useState } from "react";
import Header_General from "../components/header/Header_General";
import NavbarGeneral from "../components/navbar/Navbar_General";
import ApiController from "../controller/ApiController";

const Home = () => {

    const [data, setData] = useState([]);

    const [content0, setContent0] = useState([]);
    const [content1, setContent1] = useState([]);
    const [content2, setContent2] = useState([]);

    useEffect(() => {
        if (data.length === 0) {
            ApiController.getHome().then((response) => { setData(response); });
        }
    }, []);

    useEffect(() => {

        if (data.length !== 0) {
            data.forEach(content => {
                let contentArray = content.content.split(/(?:\r\n|\r|\n)/g);
                if (data.indexOf(content) === 0) {
                    setContent0(contentArray);
                } else if (data.indexOf(content) === 1) {
                    setContent1(contentArray);
                } else if (data.indexOf(content) === 2) {
                    setContent2(contentArray);
                }
            });
        }

    }, [data]);

    return (
        <div>
            <Header_General />
            <NavbarGeneral />
            <div className="wrapper">
                <center><img src="images/header/header.jpg" height="300" draggable="false" style={{ paddingLeft: "50px" }} /></center>
                <div className="content1">
                    <h3>
                        <p style={{ textAlign: "justify", fontFamily: 'Oswald', fontWeight: 300 }} ><img src="images/punkt.jpg" width="15" draggable="false" style={{ paddingRight: "10px" }} />
                            {content0.map(text => (
                                <>{text} <br /></>
                            ))}
                        </p>
                        <p style={{ textAlign: "justify", fontFamily: 'Oswald', fontWeight: 300 }} ><img src="images/punkt.jpg" width="15" draggable="false" style={{ paddingRight: "10px" }} />
                            {content1.map(text => (
                                <>{text} <br /></>
                            ))}
                        </p>
                        <p style={{ textAlign: "justify", fontFamily: 'Oswald', fontWeight: 300 }} ><img src="images/punkt.jpg" width="15" draggable="false" style={{ paddingRight: "10px" }} />
                            {content2.map(text => (
                                <>{text} <br /></>
                            ))}
                        </p>
                    </h3>
                </div>
                <div className="content3">
                    <video controls={true} height="280" width="420">
                        <source src="videos/Sai(ei)tensprünge.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
}

export default Home;