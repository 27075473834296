import './App.css';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';

import Home from './pages/Home';
import Exhibits from './pages/Exhibits';
import Shared from './pages/Shared';
import Andrea from './pages/Andrea';
import Hiltraud from './pages/Hiltraud';
import Galery_Andrea from './pages/Galery_Andrea';
import Galery_Hiltraud from './pages/Galery_Hiltraud';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="*" element={<Home />} />
        <Route exact path="/ausstellungen" element={<Exhibits />} />
        <Route exact path="/gemeinsames" element={<Shared />} />
        <Route exact path="/andrea" element={<Andrea />} />
        <Route exact path="/andrea/gallerie" element={<Galery_Andrea />} />
        <Route exact path="/hiltraud" element={<Hiltraud />} />
        <Route exact path="/hiltraud/gallerie" element={<Galery_Hiltraud />} />
      </Routes>
    </Router>
  );
}

export default App;