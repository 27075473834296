import React from "react";

const Header_Hiltraud = () => {

    return (
        <header>
            <center>
                <img src="../images/header/hiltraud/1.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/2.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/3.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/4.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/5.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/6.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/7.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/8.webp" height="100" draggable="false" />&nbsp;
                <img src="../images/header/hiltraud/9.webp" height="100" draggable="false" />&nbsp;
            </center>
        </header>
    );
}

export default Header_Hiltraud;



