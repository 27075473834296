import React from "react";
import { Link } from "react-router-dom";

const NavbarGeneral = () => {

    return (
        <nav>
            <p className="custom-links">
                <Link to="/">home</Link>
                <Link to="/ausstellungen">ausstellungen</Link>
                <Link to="/andrea">andrea baltzer</Link>
                <Link to="/gemeinsames">gemeinsames</Link>
                <Link to="/hiltraud">hiltraud todor</Link>
                <Link >kontakt</Link>
            </p>
        </nav>
    );

}

export default NavbarGeneral;