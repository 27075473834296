import React, { useEffect, useState } from "react";
import Header_General from "../components/header/Header_General";
import NavbarGeneral from "../components/navbar/Navbar_General";
import ApiController from "../controller/ApiController";

const Exhibits = () => {
    const [data, setData] = useState([]);
    const [contentMap, setContentMap] = useState(new Map());

    useEffect(() => {
        if (data.length === 0) {
            ApiController.getExhibits().then((response) => {
                setData(response);
            });
        }
    }, []);

    useEffect(() => {
        if (data.length !== 0) {

            const exhibitMap = new Map();

            // Loop through the provided data and populate the map
            data.forEach((exhibitsByYear) => {
                for (const year in exhibitsByYear) {
                    if (exhibitsByYear.hasOwnProperty(year)) {
                        const yearNumber = parseInt(year, 10);
                        const exhibits = exhibitsByYear[year];
                        if (!exhibitMap.has(yearNumber)) {
                            exhibitMap.set(yearNumber, exhibits);
                        } else {
                            exhibitMap.get(yearNumber).push(...exhibits);
                        }
                    }
                }
            });

            setContentMap(exhibitMap);
        }
    }, [data]);

    const renderExhibits = () => {
        const exhibits = [];
        const years = Array.from(contentMap.keys()).sort((a, b) => b - a);

        // Iterate through the exhibitMap and render each year and its exhibits
        years.forEach((year) => {
            const yearParagraph = (
                <p
                    style={{
                        textAlign: "justify",
                        fontFamily: "Oswald",
                        fontWeight: 400,
                        color: "#FFFFFF",
                        fontSize: "20px",
                    }}
                    key={year}
                >
                    {year}
                </p>
            );

            const exhibitArray = contentMap.get(year);
            const exhibitParagraphs = exhibitArray.map((exhibit) => (
                <p
                    className="rubi"
                    style={{
                        textAlign: "justify",
                        fontFamily: "Oswald",
                        fontWeight: 300,
                        color: "#999",
                        fontSize: "18px",
                    }}
                    key={exhibit.id}
                >
                    <img src="images/punkt.jpg" width="15" alt="punkt" />
                    <span text="">{exhibit.information}</span>
                    <br />
                </p>
            ));

            exhibits.push(yearParagraph, ...exhibitParagraphs);
        });

        return exhibits;
    };

    const renderedExhibits = renderExhibits();

    return (
        <div>
            <Header_General />
            <NavbarGeneral />
            <div className="wrapper">
                <div className="aktuelles1">
                    {renderedExhibits}
                </div>
            </div>
        </div>
    );
};

export default Exhibits;