import React, { useState, useEffect } from "react";
import Header_General from "../components/header/Header_General";
import NavbarGeneral from "../components/navbar/Navbar_General";
import ApiController from "../controller/ApiController";

const Shared = () => {

    const [data, setData] = useState([]);
    const [content, setContent] = useState([]);

    useEffect(() => {
        if(Object.keys(data).length === 0){
            ApiController.getShared().then((response) => {setData(response);});
        }
    }, []);

    useEffect(() => {
        if(Object.keys(data).length !== 0){
            let contentArray = data.sharedText.split(/(?:\r\n|\r|\n)/g);
            setContent(contentArray);
        }

    },[data]);

    return (
        <div>
            <Header_General />
            <NavbarGeneral />
            <div className="wrapper">
                <div className="content4">
                    <center>
                        <h3>
                            <hr color="#999" />
                            <table>
                                <tr><td></td><td></td></tr>
                                <tr><td>
                                    <p align="justify" style={{ fontFamily: 'Oswald', fontWeight: 300 }}>
                                        <img src="images/punkt.jpg" width="15" />
                                        <span style={{ textAlign: "justify", fontFamily: 'Oswald', fontWeight: 300 }}>{content.map(text => (
                                            <>{text} <br /></>
                                        ))}</span>
                                    </p>
                                </td>

                                    <br />
                                    <td style={{ paddingLeft: "15%" }}><img src="images/colaboration/unddu.jpg" height="400" draggable="false" /></td>
                                </tr>
                            </table>
                        </h3>
                    </center>
                </div>
            </div>
        </div>
    );
}

export default Shared;