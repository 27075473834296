import React from "react";

class ApiController{
    constructor(){
        if(ApiController.instance){
            return ApiController.instance;
        }
    }

    async getHome(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/home");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getExhibits(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/exhibits");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getShared(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/shared");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getPersonalAndrea(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/andrea/personal");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getPersonalHiltraud(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/hiltraud/personal");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getGaleryHiltraud(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/hiltraud/galery");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }

    async getGaleryAndrea(){
        let data = [];
        try{
            const response = await fetch("https://api.grazermalweiber.at/andrea/galery");

            if(!response.ok){
                console.log("Error", response);
            }

            data = await response.json();
            return data;
        } catch(err){
            console.log(err);
            return data;
        }
    }
}
const apiController = new ApiController();
export default apiController;