import React, { useEffect, useState } from "react";
import Header_Andrea from "../components/header/Header_Andrea";
import Navbar_Andrea from "../components/navbar/Navbar_Andrea";
import ApiController from "../controller/ApiController";
import ModalImage from "react-modal-image";

const Galery_Andrea = () => {
  const [data, setData] = useState([]);
  const [galeryMap, setGaleryMap] = useState(new Map());
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (data.length === 0) {
      ApiController.getGaleryAndrea().then((response) => {
        setData(response);
      });
    }
  }, []);

  useEffect(() => {
    if (data.length !== 0) {
      let catMap = new Map();
      data.forEach((image) => {
        if (!catMap.has(image.category)) {
          catMap.set(image.category, [image]);
        } else {
          catMap.get(image.category).push(image);
        }
      });
      setGaleryMap(catMap);

      // Automatically select the first category if not already selected
      if (selectedCategory === null) {
        const firstCategory = Array.from(catMap.keys())[0];
        setSelectedCategory(firstCategory);
      }
    }
  }, [data, selectedCategory]);

  return (
    <div>
      <Header_Andrea />
      <Navbar_Andrea />
      <div className="wrapper">
        <div className="themen">
          <ul style={{ listStyleType: "none" }}>
            {Array.from(galeryMap.keys()).map((category) => (
              <li key={category} style={{ fontFamily: 'Oswald', fontWeight: "300" }}>
                <a href="#" onClick={() => setSelectedCategory(category)}>
                  {category}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {Array.from(galeryMap.keys()).map((category) => (
          <div className={`bilder ${category !== selectedCategory ? 'hidden' : ''}`} id={category} key={category}>
            <div>
              <p style={{ fontFamily: 'Oswald', fontWeight: 300 }}>{category}</p>
              <table>
                <tbody>
                  {Array.from({ length: Math.ceil(galeryMap.get(category).length / 5) }).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                      {galeryMap.get(category).slice(rowIndex * 5, (rowIndex + 1) * 5).map((image, index) => (
                        <td key={index}>
                          <ModalImage
                            small={"../" + process.env.PUBLIC_URL + image.thumbnailPath}
                            large={"../" + process.env.PUBLIC_URL + image.originalPath}
                            alt={image.imageName}
                            hideDownload={true}
                            hideZoom={true}
                            showRotate={false}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default Galery_Andrea;
